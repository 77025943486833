import { makeAutoObservable } from "mobx";

export interface GrammarItemType {
  text: string;
  id: string;
  scriptId: string;
}
export default class Speech {
  dictionaryLookupTable: { [word: string]: string[] };
  //@ts-ignore
  recognition: SpeechRecognition;
  recognizingSpeech: boolean;
  gramarById: { [id: string]: GrammarItemType };
  autoRestart: boolean;
  abort: boolean;
  onInterimResult: (speechRecognitionResult: SpeechRecognitionResult) => void;
  onFinalResult: (speechRecognitionResult: SpeechRecognitionResult) => void;

  constructor(
    onFinalResult: (speechRecognitionResult: SpeechRecognitionResult) => void,
    onInterimResult: (speechRecognitionResult: SpeechRecognitionResult) => void
  ) {
    this.autoRestart = true;
    this.recognizingSpeech = false;
    //@ts-ignore
    const SpeechRecognition = webkitSpeechRecognition;
    this.recognition = new SpeechRecognition();
    this.dictionaryLookupTable = {};
    this.gramarById = {};
    this.onFinalResult = onFinalResult;
    this.onInterimResult = onInterimResult;
    this.abort = false;
    makeAutoObservable(this);
  }
  get isRecognizingSpeech(): boolean {
    return this.recognizingSpeech;
  }

  setRecognizingSpeech(value: boolean) {
    this.recognizingSpeech = value;
  }

  setAutoRestart(value: boolean) {
    this.autoRestart = value;
  }

  setAbortAllSubsequentResults() {
    this.abort = true;
  }

  start = () => {
    console.log("starting speech");
    this.setAutoRestart(true);
    this.abort = false;
    this.recognition.stop();
    //@ts-ignore
    const SpeechRecognition = webkitSpeechRecognition; // we only need chrome
    // WEB SPEECH API SET UP
    this.recognition = new SpeechRecognition();

    // set up speech recognition settings
    this.recognition.continuous = true;
    this.recognition.interimResults = true;
    // this.recognition.lang = this.language;
    this.recognition.maxAlternatives = 1;
    this.recognition.onerror = this.onError.bind(this);
    // this.recognition.onnomatch = this.onNoMatch.bind(this);
    this.recognition.onresult = this.onResult.bind(this);
    // this.recognition.onsoundend = this.onSoundEnd.bind(this);
    // this.recognition.onaudiostart = this.onSoundStart.bind(this);
    // this.recognition.onsoundstart = this.onSoundStart.bind(this);
    this.recognition.onspeechend = this.onSpeechEnd.bind(this);

    if (!this.isRecognizingSpeech) {
      // only start recognition if we have not started it yet
      try {
        this.recognition.start();

        this.setRecognizingSpeech(true);
      } catch (error) {
        //"Attempted to start speech recognition but something happened",
      }
    } else {
      // "Speech is already being recognized, skipping call to start"
    }
  };

  stop = () => {
    this.setAbortAllSubsequentResults();
    this.setAutoRestart(false);
    if (this.recognition) {
      this.recognition.stop();

      this.setRecognizingSpeech(false);
    } else {
    }
  };

  //@ts-ignore
  onResult(event: SpeechRecognitionEvent) {
    if (this.abort) {
      return;
    }
    // The SpeechRecognitionEvent results property returns a SpeechRecognitionResultList object
    // The SpeechRecognitionResultList object contains SpeechRecognitionResult objects.
    // It has a getter so it can be accessed like an array
    // The first [0] returns the SpeechRecognitionResult at the last position.
    // Each SpeechRecognitionResult object contains SpeechRecognitionAlternative objects that contain individual results.
    // These also have getters so they can be accessed like arrays.
    // The second [0] returns the SpeechRecognitionAlternative at position 0.
    // We then return the transcript property of the SpeechRecognitionAlternative object
    const resultIndex = event.resultIndex;
    const speechRecognitionResult = event.results[resultIndex];
    if (speechRecognitionResult) {
      if (speechRecognitionResult.isFinal) {
        this.onFinalResult(speechRecognitionResult);
      } else {
        this.onInterimResult(speechRecognitionResult);
      }
    }
  }

  onSpeechEnd = (event: any) => {
    this.setRecognizingSpeech(false);
    if (this.autoRestart) {
      this.start();
    } else {
      this.recognition.stop();
    }
  };

  onError = (event: any) => {
    const error = event.error;
    this.setRecognizingSpeech(false);

    // on error restart recognition
    switch (error) {
      case "network":
        // restart speech recognition
        this.start();
        break;
      case "not-allowed":
      case "service-not-allowed":
        // if permission to use the mic is denied, turn off auto-restart
        // TODO: prompt for microphone

        // restart speech recognition
        this.start();
        break;
      case "no-speech":
        // restart speech recognition
        this.start();
        break;
      default:
        this.start();
    }
  };

  /**
   * Should return a string without punctuation and all lowercase.
   * @param text the text with punctuation
   */
  static cleanKey = (text: string): string => {
    return text
      .replace(/-/, " ")
      .replace(/[^A-Za-z0-9\s]/g, "")
      .replace(/\s{2,}/g, " ")
      .toLocaleLowerCase()
      .trim();
  };

  static cleanAlphaNumeric = (text: string): string => {
    return text
      .replace(/-/, " ")
      .replace(/[^A-Za-z\s]/g, "")
      .replace(/\s{2,}/g, " ")
      .toLocaleLowerCase()
      .trim();
  };
}
