import CloudsImage from "../../images/clouds.svg";
import { css } from "glamor";
import { observer } from "mobx-react";
import {
  defaultStyles,
  FontFamilies,
  FontSizes,
  spacings,
} from "../../styles/default";
import { sightWords } from "../../store/sightWords/sightWords";
import { windowSizes } from "../../store/WindowSizesStore";
import GameStore from "../../store/sightWords/Game";
import { Dropdown } from "../Dropdown/Dropdown";
import DropdownStore from "../Dropdown/DropdownStore";
import Microphone from "../../store/Microphone";
import { logEvent } from "@firebase/analytics";
import { user } from "../../store/User";
import MicrophoneImage from "../../images/microphone.svg";
import "animate.css";
import WordCard from "./WordCard";

export const Game: React.FC<{ game: GameStore }> = observer(({ game }) => {
  const words = game.sightWords;

  return (
    <div
      {...css({
        position: "absolute",
        width: windowSizes.width,
        height: windowSizes.height,
        top: 0,
        left: 0,
        zIndex: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      })}
    >
      {game.gameOver && <GameOver game={game} />}
      <div
        {...css({
          position: "relative",
          zIndex: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        })}
      >
        <ScoreBoard game={game} />
        {!game.microphone.isReady && (
          <MicrophoneSelector microphone={game.microphone} />
        )}
        {!game.gameStarted && game.microphone.isReady && (
          <SelectLevel game={game} />
        )}

        {/* {words.map((word) => {
          return <div>{word}</div>;
        })} */}
        {game.gameStarted && (
          <div {...css({ width: "300px" })}>
            <WordCard
              word={game.currentWord}
              swapCard={game.swapCard}
              guess={game.guess}
            />
          </div>
        )}
        {!game.gameStarted && game.level >= 0 && (
          <button
            {...css(defaultStyles.button.gameLevel, {
              fontFamily: FontFamilies.game,
            })}
            onClick={() => {
              game.startGame();
            }}
          >
            Start game
          </button>
        )}
      </div>

      <div {...css({ position: "absolute", top: 0, left: 0, zIndex: 1 })}>
        <img src={CloudsImage} alt="clouds" />
      </div>
    </div>
  );
});

const GameOver: React.FC<{ game: GameStore }> = observer(({ game }) => {
  return (
    <div
      {...css({
        display: "flex",
        position: "absolute",
        zIndex: 10,
        backgroundColor: "green",
        height: windowSizes.height,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      })}
    >
      <span
        {...css({
          fontFamily: FontFamilies.game,
          fontSize: FontSizes.fontSize13,
        })}
      >
        Game over
      </span>
      <button
        onClick={() => {
          game.restartGame();
        }}
      >
        Play again
      </button>
    </div>
  );
});

const ScoreBoard: React.FC<{ game: GameStore }> = observer(({ game }) => {
  if (!game.gameStarted) {
    return null;
  }
  return (
    <div
      {...css({
        display: "flex",
        gridGap: spacings.space2,
      })}
    >
      <div>Level: {game.level}</div>
      <div>Timer: {game.timer?.currentTime}</div>
      Score: {game.points}
    </div>
  );
});

export const MicrophoneSelector: React.FC<{ microphone: Microphone }> =
  observer(({ microphone }) => {
    const selectedMic = microphone.selectedDeviceOption;

    const dropdownStore = new DropdownStore({
      id: "microphone-selection",
      selected: {
        value: selectedMic?.value || "",
        label: selectedMic?.label || "",
      },
      options: microphone.microphoneOptions.map((option) => ({
        value: option.value,
        label: option.label,
        action: () => {
          logEvent(user.analytics, "select_microphone");

          microphone.onMicrophoneChange({
            label: option.label,
            value: option.value,
          });
        },
      })),
    });
    return (
      <div {...css({ width: "300px", marginTop: spacings.space8 })}>
        <img src={MicrophoneImage} alt="microphone" />
        <Dropdown
          label="Select your microphone"
          dropdownStore={dropdownStore}
          style={{
            label: {
              color: "black",
              fontFamily: FontFamilies.game,
              fontSze: "25px",
            },
            selectedText: {
              color: "black",
            },
            selected: {
              color: "black",
              padding: spacings.space2,
              height: "24px",
              borderColor: "black",
            },
            arrowIcon: {
              color: "black",
            },
            list: {
              backgroundColor: "#6498D2",
            },
            listItem: {
              ":hover": {
                backgroundColor: "#C1D7F5",
              },
            },
          }}
        />
      </div>
    );
  });

export const SelectLevel: React.FC<{ game: GameStore }> = observer(
  ({ game }) => {
    return (
      <div
        {...css({
          display: "flex",
          gridGap: spacings.space4,
          alignItems: "center",
          justifyContent: "center",
          margin: spacings.space6,
          flexDirection: "column",
        })}
      >
        <h1 {...css({ fontFamily: FontFamilies.game })}>Select Level</h1>
        <div
          {...css({
            display: "flex",
            gridGap: spacings.space4,
            margin: spacings.space6,
          })}
        >
          {sightWords.map((levels, i) => {
            return (
              <button
                {...css(defaultStyles.button.gameLevel, { fontSize: "30" })}
                onClick={() => {
                  game.setLevel(i);
                }}
              >
                {game.unlockedLevels.includes(i) && <span> Level {i + 1}</span>}
                {!game.unlockedLevels.includes(i) && (
                  <span className="material-icons">lock</span>
                )}
              </button>
            );
          })}
        </div>
      </div>
    );
  }
);
