import { makeAutoObservable } from "mobx";

export type TimerType = "countdown" | "stopwatch";
class Timer {
  type: TimerType;
  currentTick: number;
  requestFrameId?: any;
  currentTime: any;
  includeSound: boolean;
  countdown?: number;
  countdownCompleted: boolean;

  constructor(type: TimerType, countdown?: number, includeSound?: boolean) {
    this.type = type;
    this.currentTick = 0;
    this.includeSound = includeSound || false;
    this.countdown = countdown;
    this.countdownCompleted = false;
    makeAutoObservable(this);
  }

  start = () => {
    this.currentTick = new Date().getTime();
    this.requestFrameId = requestAnimationFrame(this.tick);
    if (this.includeSound) {
    }
  };

  tick = () => {
    const now = new Date().getTime();
    const time = (now - this.currentTick) / 1000;
    if (this.type === "countdown" && this.countdown) {
      this.currentTime = (this.countdown - time).toFixed(0);
    } else {
      this.currentTime = time.toFixed(0);
    }
    if (this.currentTime > 0) {
      this.requestFrameId = requestAnimationFrame(this.tick);
    } else {
      this.setCountdownCompleted(true);
      this.stop();
    }
  };

  setCountdownCompleted = (value: boolean) => {
    this.countdownCompleted = value;
  };

  stop = () => {
    cancelAnimationFrame(this.requestFrameId);
  };

  playTimerSound = () => {
    var audio = new Audio("https://dottedtext.com/audio/clockTicking.wav");
    audio.play();
  };
}

export default Timer;
