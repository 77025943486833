import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AppStore from "./store/AppStore";
import { observer } from "mobx-react";
import { startRouter } from "./store/routes";

const appStore = new AppStore();
startRouter(appStore);

const Wrapper: React.FC<{ store: AppStore }> = observer(({ store }) => {
  return (
    <React.StrictMode>
      <App store={store} />
    </React.StrictMode>
  );
});
ReactDOM.render(<Wrapper store={appStore} />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
