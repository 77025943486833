import { logEvent } from "@firebase/analytics";
import { css } from "glamor";
import { observer } from "mobx-react";
import { v4 } from "uuid";
import AppStore from "../store/AppStore";
import { user } from "../store/User";
import {
  fontFamilyOptions,
  fontLabels,
  WritingSection,
} from "../store/WritingSection";
import {
  borderRadius,
  Color,
  defaultStyles,
  FontFamilies,
  FontSizes,
  InputStyle,
  queries,
  setAlpha,
  spacings,
} from "../styles/default";
import InputWithArrows from "./common/InputWithArrows";
import { Dropdown } from "./Dropdown/Dropdown";
import DropdownStore from "./Dropdown/DropdownStore";
import { Input } from "./Input";
import TextRepetition from "./TextRepetition";

const WritingSectionComponent: React.FC<{
  showPreview: boolean;
  onClosePreview: () => void;
  onRemove: () => void;
  writingSection: WritingSection;
  store: AppStore;
}> = ({ writingSection, showPreview, onClosePreview, onRemove, store }) => {
  const { id, text, includeNonDotted, repeat, fontSize, fontFamily } =
    writingSection;

  const dropdownStore = new DropdownStore({
    id: `font-family-selector-${id}`,
    selected: { value: fontFamily, label: fontLabels[fontFamily] },
    options: fontFamilyOptions.map((option) => ({
      value: option.font,
      label: option.label,
      action: () => {
        logEvent(user.analytics, "change_fontFamily", {
          fontFamily: option.font,
        });
        writingSection.setFontFamily(option.font);
      },
    })),
  });

  return (
    <div
      key={id}
      {...css({
        padding: spacings.space4,
        borderRadius: borderRadius.borderRadius3,
        marginBottom: spacings.space4,
        border: showPreview
          ? 0
          : `1px solid ${setAlpha(Color.onBackground, 0.2)}`,
        position: "relative",
        [queries.print]: {
          border: 0,
          padding: 0,
          borderRadius: 0,
          overflow: "hidden",
        },
      })}
    >
      {!showPreview && (
        <div>
          <div {...css({ display: "flex", alignItems: "flex-start" })}>
            {/* <div {...css({ marginTop: "30px" })}>
            <img src={WhiteArrow} alt="Arrow" width={80} />
          </div> */}

            <div {...css({ width: "100%" })}>
              <div
                {...css({
                  display: "flex",
                  gridGap: spacings.space6,
                  [queries.phone]: {
                    flexDirection: "column",
                  },
                })}
              >
                <Input
                  placeholder="Enter text"
                  label="Enter text"
                  value={text}
                  onChange={(e: any) => {
                    writingSection.setText(e.target.value);
                  }}
                  style={{
                    input: InputStyle,
                  }}
                />
                <SelectFromTemplate
                  store={store}
                  writingSection={writingSection}
                />
              </div>

              {/* <Checkbox
                label="Include non dotted example"
                id="include-original"
                checked={includeNonDotted}
                onToggle={() => {
                  logEvent(user.analytics, "toggle_include_nonDottedExample");
                  writingSection.setIncludeNonDotted(!includeNonDotted);
                }}
              /> */}
            </div>
          </div>

          <div
            {...css({
              display: "flex",
              gridGap: spacings.space8,
              alignItems: "flex-start",
              width: "100%",
              marginTop: spacings.space2,
              [queries.phone]: {
                flexDirection: "column",
                gridGap: spacings.space2,
              },
              [queries.print]: {
                visibility: "hidden",
                height: 0,
              },
            })}
          >
            <div
              {...css({
                display: "flex",
                gridGap: spacings.space4,
                flexDirection: "column",
              })}
            >
              <div
                {...css({
                  display: "flex",
                  gridGap: spacings.space4,
                  alignItems: "center",
                })}
              >
                <div {...css({ width: "80px", marginRight: spacings.space4 })}>
                  <InputWithArrows
                    value={repeat.toString()}
                    label="Repeat"
                    onChange={(value) => {
                      logEvent(user.analytics, "change_repeat_value", {
                        value,
                      });
                      writingSection.setRepeat(parseInt(value));
                    }}
                    styles={InputStyle}
                  />
                </div>
                <div {...css({ width: "100px" })}>
                  <InputWithArrows
                    value={fontSize.toString()}
                    label="Letter size"
                    onChange={(value) => {
                      logEvent(user.analytics, "change_fontSize", { value });
                      writingSection.setFontSize(parseInt(value));
                    }}
                    styles={InputStyle}
                  />
                </div>
              </div>
              <div {...css({ width: "180px" })}>
                <Dropdown
                  label="Font type"
                  dropdownStore={dropdownStore}
                  style={{
                    selected: {
                      padding: spacings.space2,
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <TextRepetition
        key={`writing-${writingSection.id}`}
        writingSection={writingSection}
        store={store}
      />

      {!showPreview && store.sectionCount > 1 && (
        <button
          {...css(defaultStyles.button.defaultSmall, {
            backgroundColor: "transparent",
            border: `1px solid ${Color.secondary}`,
            position: "absolute",
            top: spacings.space2,
            right: spacings.space3,
          })}
          onClick={() => onRemove()}
        >
          <span
            className="material-icons"
            {...css({ fontSize: FontSizes.fontSize4 })}
          >
            close
          </span>{" "}
          Remove
        </button>
      )}
    </div>
  );
};

export default observer(WritingSectionComponent);

const SelectFromTemplate: React.FC<{
  store: AppStore;
  writingSection: WritingSection;
}> = observer(({ store, writingSection }) => {
  const templates = [
    {
      value: "Pangram (Dotted)",
      label: "Pangram (Dotted)",
      settings: {
        id: v4(),
        label: "Pangram (Dotted)",
        text: "A quick brown fox jumps over the lazy dog",
        fontFamily: FontFamilies.dotted,
        fontSize: 100,
        repeat: 1,
        includeNonDotted: false,
        color: Color.onBackground,
      },
    },
    {
      value: "Pangram (Cursive)",
      label: "Pangram (Cursive)",
      settings: {
        id: v4(),
        label: "Pangram (Cursive)",
        text: "A quick brown fox jumps over the lazy dog",
        fontFamily: FontFamilies.cursive,
        fontSize: 100,
        repeat: 1,
        includeNonDotted: false,
        color: Color.onBackground,
      },
    },
    {
      value: "Alphabet in Lowercase (Cursive)",
      label: "Alphabet in Lowercase (Cursive)",
      settings: {
        id: v4(),
        label: "Alphabet in Lowercase (Cursive)",
        text: "a b c d e f g h i j k l m n o p q r s t u v w x y z",
        fontFamily: FontFamilies.cursive,
        fontSize: 100,
        repeat: 1,
        includeNonDotted: false,
        color: Color.onBackground,
      },
    },
    {
      value: "Alphabet in Uppercase (Cursive)",
      label: "Alphabet in Uppercase (Cursive)",
      settings: {
        id: v4(),
        label: "Alphabet in Uppercase (Cursive)",
        text: "A B C D E F G H I J K L M N O P Q R S T U V W X Y Z",
        fontFamily: FontFamilies.cursive,
        fontSize: 100,
        repeat: 1,
        includeNonDotted: false,
        color: Color.onBackground,
      },
    },
    {
      value: "Numbers 1 to 10 (Cursive)",
      label: "Numbers 1 to 10 (Cursive)",
      settings: {
        id: v4(),
        label: "Numbers 1 to 10 (Cursive)",
        text: "one two three four five six seven eight nine ten",
        fontFamily: FontFamilies.cursive,
        fontSize: 100,
        repeat: 1,
        includeNonDotted: false,
        color: Color.onBackground,
      },
    },
    {
      value: "Numbers 1 to 10 (Dotted)",
      label: "Numbers 1 to 10 (Dotted)",
      settings: {
        id: v4(),
        label: "Numbers 1 to 10 (Dotted)",
        text: "1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20",
        fontFamily: FontFamilies.cursive,
        fontSize: 100,
        repeat: 1,
        includeNonDotted: false,
        color: Color.onBackground,
      },
    },
    {
      value: "Numbers 1 to 20 (Dotted)",
      label: "Numbers 1 to 20 (Dotted)",
      settings: {
        id: v4(),
        label: "Numbers 1 to 10 (Dotted)",
        text: "1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20",
        fontFamily: FontFamilies.cursive,
        fontSize: 100,
        repeat: 1,
        includeNonDotted: false,
        color: Color.onBackground,
      },
    },
    {
      value: "Sight words Level 1 (Cursive)",
      label: "Sight words Level 1 (Cursive)",
      settings: {
        id: v4(),
        label: "Sight words Level 1 (Cursive)",
        text: "I see the yellow look green blue",
        fontFamily: FontFamilies.cursive,
        fontSize: 100,
        repeat: 1,
        includeNonDotted: false,
        color: Color.onBackground,
      },
    },
    {
      value: "Sight words Level 2 (Cursive)",
      label: "Sight words Level 2 (Cursive)",
      settings: {
        id: v4(),
        label: "Sight words Level 2 (Cursive)",
        text: "this is like and for here go purple pink orange",
        fontFamily: FontFamilies.cursive,
        fontSize: 100,
        repeat: 1,
        includeNonDotted: false,
        color: Color.onBackground,
      },
    },
    {
      value: "Sight words Level 3 (Cursive)",
      label: "Sight words Level 3 (Cursive)",
      settings: {
        id: v4(),
        label: "Sight words Level 3 (Cursive)",
        text: "you what to do me we he she have come with",
        fontFamily: FontFamilies.cursive,
        fontSize: 100,
        repeat: 1,
        includeNonDotted: false,
        color: Color.onBackground,
      },
    },
    {
      value: "Sight words Level 4 (Cursive)",
      label: "Sight words Level 4 (Cursive)",
      settings: {
        id: v4(),
        label: "Sight words Level 4 (Cursive)",
        text: "good they brown white black one two three four five six",
        fontFamily: FontFamilies.cursive,
        fontSize: 100,
        repeat: 1,
        includeNonDotted: false,
        color: Color.onBackground,
      },
    },
    {
      value: "Sight words Level 6 (Cursive)",
      label: "Sight words Level 6 (Cursive)",
      settings: {
        id: v4(),
        label: "Sight words Level 6 (Cursive)",
        text: "how now said put all who your seven eight nine ten",
        fontFamily: FontFamilies.cursive,
        fontSize: 100,
        repeat: 1,
        includeNonDotted: false,
        color: Color.onBackground,
      },
    },
  ];
  const selectedTemplateId = writingSection.id;

  const dropdownStore = new DropdownStore({
    id: selectedTemplateId,
    selected: { value: selectedTemplateId, label: writingSection.label },
    options: templates.map((option) => ({
      value: option.value,
      label: option.label,
      action: () => {
        logEvent(user.analytics, "select_from_template", {
          templateId: selectedTemplateId,
        });

        store.writingSections[selectedTemplateId].setText(option.settings.text);
        store.writingSections[selectedTemplateId].setLabel(
          option.settings.label
        );
        store.writingSections[selectedTemplateId].setRepeat(
          option.settings.repeat
        );
        store.writingSections[selectedTemplateId].setFontSize(
          option.settings.fontSize
        );
        store.writingSections[selectedTemplateId].setFontFamily(
          option.settings.fontFamily
        );
      },
    })),
  });
  return (
    <div
      {...css({
        display: "flex",
      })}
    >
      <div {...css({ width: "300px" })}>
        <Dropdown
          label="or ... Select from template"
          dropdownStore={dropdownStore}
          style={{
            selected: {
              padding: spacings.space2,
            },
          }}
        />
      </div>
    </div>
  );
});
