import { Router } from "director/build/director";
import { autorun } from "mobx";
import AppStore from "./AppStore";

export function startRouter(store: AppStore) {
  // update state on url change
  new Router({
    "/": () => store.openHome(),
    "/templates": () => store.openTemplatesView(),
    "/create": () => store.openCreateYourOwnView(),
    "/game": () => store.openGame(),
  })
    .configure({
      before: () => {},
      notfound: () => store.openNotFoundView(),
      html5history: true,
    })
    .init();

  // update url on state changes
  autorun(() => {
    let path = store.view.path;
    let title = "";
    if (document && store.view.title) {
      title = document.title;
    }
    window.history.pushState(null, title, path);
  });
}
