import { autorun, makeAutoObservable } from "mobx";
import AudioDetectionSettings from "../AudioDetectionSettings";
import Microphone from "../Microphone";
import Speech from "../SpeechRecognition";
import Timer from "../Timer";
import { sightWords } from "./sightWords";

class GameStore {
  level: number;
  wordIndex: number;
  microphone: Microphone;
  speechRecognition?: Speech;
  audioDetectionSettings?: AudioDetectionSettings;
  guess?: string;
  points: number;
  swapCard?: "front" | "back";
  gameStarted: boolean;
  unlockedLevels: number[];
  timer?: Timer;
  gameOver: boolean;
  correctlyGuessedWords: { [word: string]: number };

  constructor() {
    this.wordIndex = 0;
    this.points = 0;
    this.level = -1;
    this.gameStarted = false;
    this.unlockedLevels = [0];
    this.audioDetectionSettings = this.audioDetectionSettings;
    this.gameOver = false;
    this.correctlyGuessedWords = {};
    this.microphone = new Microphone(
      this.audioDetectionSettings,
      this.handleSoundStart,
      this.handleSoundEnd,
      this.onMicrophoneInputChange
    );
    this.speechRecognition = new Speech(
      this.onFinalSpeech,
      this.onInterimSpeech
    );

    autorun(() => {
      // watch for leveling up
      if (this.timer?.countdownCompleted && this.gameStarted) {
        this.attemptToLevelUp();
      }
    });

    makeAutoObservable(this);
    // initialize microphone
    // this will ensure microphone's list is refreshed as soon as the user
    // loads the page
    this.microphone.init();
  }

  get currentWord() {
    const wordIndex = this.wordIndex;
    return this.sightWords[wordIndex];
  }

  get sightWords(): string[] {
    const level = this.level;
    if (level >= 0) {
      return sightWords[level];
    }

    return [];
  }
  attemptToLevelUp = () => {
    if (this.canLevelUp()) {
      // level up
      this.levelUp();
      this.unlockNextLevel();
      this.restartTimer();
    } else {
      this.setGameOver(true);
      this.timer?.stop();
    }
  };

  restartGame = () => {
    this.setGameOver(false);
    this.setLevel(-1);
    this.setGameStarted(false);
    this.resetGuessedWords();
  };

  canLevelUp = (): boolean => {
    let canUp = true;
    sightWords[this.level].forEach((word) => {
      if (!this.correctlyGuessedWords[word]) {
        canUp = false;
      }
    });
    return canUp;
  };

  setGameOver = (value: boolean) => {
    this.gameOver = value;
  };

  unlockNextLevel = () => {
    this.unlockedLevels = [...this.unlockedLevels, this.level];
  };

  levelUp = () => {
    this.setLevel(this.level + 1);
  };

  restartTimer = () => {
    this.timer?.start();
  };

  setTimer = (timer: Timer) => {
    this.timer = timer;
  };

  swapCards = () => {
    if (!this.swapCard) {
      this.swapCard = "front";
    } else {
      if (this.swapCard !== undefined) {
        if (this.swapCard === "front") {
          this.swapCard = "back";
        } else {
          this.swapCard = "front";
        }
      }
    }
  };

  setGameStarted = (value: boolean) => {
    this.gameStarted = value;
  };

  startGame = () => {
    this.setGameStarted(true);
    this.showNewWord();
    const timer = new Timer("countdown", 60);
    this.setTimer(timer);
    this.timer?.start();
  };

  showNewWord = () => {
    const max = this.sightWords.length;
    const randomWordIndex = Math.floor(Math.random() * max);
    // const element = document.querySelector("#current-word");
    // animateCSS("#current-word", "bounce");
    this.setWordIndex(randomWordIndex);
    this.swapCards();
  };

  playSuccess = () => {
    var audio = new Audio("https://dottedtext.com/audio/correct.wav");
    audio.play();
  };

  addPoint = () => {
    this.points = this.points + 1;
  };

  setGuess = (guess: string) => {
    this.guess = guess;
    const wordsInGuess = guess.trim().split(" ");
    const currentWord = this.currentWord?.trim();
    if (wordsInGuess.includes(currentWord)) {
      this.playSuccess();
      this.showNewWord();
      this.addPoint();
      this.addToGuessedWords(currentWord);
    }
  };

  addToGuessedWords = (word: string) => {
    const guessedBefore = this.correctlyGuessedWords[word];
    if (guessedBefore) {
      this.correctlyGuessedWords[word] = guessedBefore + 1;
    } else {
      this.correctlyGuessedWords[word] = 1;
    }
  };
  resetGuessedWords = () => {
    this.correctlyGuessedWords = {};
  };

  onFinalSpeech = (result: any) => {
    const word = result[0].transcript;
    this.setGuess(word);
  };

  onInterimSpeech = (result: any) => {
    const word = result[0].transcript;
    this.setGuess(word);
  };

  setWordIndex(index: number) {
    this.wordIndex = index;
  }

  setLevel(level: number) {
    this.level = level;
  }

  handleSoundStart = () => {
    // only handle auto recording if auto mode is on
    console.log("sound started");
  };

  handleSoundEnd = () => {
    console.log("sound ended");
  };

  setSpeechRecognition = (speech?: Speech) => {
    // and create a new instance for it
    this.speechRecognition = speech;
  };

  startSpeechRecognition = () => {
    // let's stop any previous speech recognition already started
    if (this.speechRecognition) {
      this.speechRecognition.stop();
      this.setSpeechRecognition(undefined);
    }
    // let's create a new instance
    this.setSpeechRecognition(
      new Speech(this.onFinalSpeech, this.onInterimSpeech)
    );

    this.speechRecognition?.start();
  };

  resetSpeechRecognition = () => {
    this.startSpeechRecognition();
  };

  onMicrophoneInputChange = (deviceId: string, streamId: string) => {
    const disposer = autorun(() => {
      if (this.microphone.isReady) {
        this.resetSpeechRecognition();
        disposer();
      }
    });

    // we should immediately stop current recording
  };
}

export default GameStore;
