import {
  action,
  autorun,
  makeAutoObservable,
  reaction,
  runInAction,
} from "mobx";
import AudioDetectionSettings from "./AudioDetectionSettings";
import { audioLog } from "./AudioLog";
import Visualizer from "./Visualizer";

export const translate = (label: string) => {
  return label;
};

export const DEFAULT_STREAM_DETECTION_SETTINGS = {
  minAudioDuration: 1.0, // (in seconds) A number too high could results in short word like "Okay" being ignored
  silenceDelay: 1.0, // (in Seconds) A number too low could result in a dialogue being divided in multiple takes
  minSoundLevel: 0.05, // The lower this number is, the louder sound has to be to not be considered as silence
};

export enum AudioMeterType {
  frequency = "frequency",
  wave = "wave",
  bar = "bar",
}

export interface MicrophoneOption {
  value: string;
  label: string;
  device?: any;
}
// See more for microphone options here:
// https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackConstraints
// And here: https://w3c.github.io/mediacapture-main/#def-constraint-latency

export interface MicrophoneInfo {
  channelCount: {
    min: number | undefined;
    max: number | undefined;
    value: number | undefined;
  };
  sampleRate: {
    min: number | undefined;
    max: number | undefined;
    value: number | undefined;
  };
  sampleSize: {
    min: number | undefined;
    max: number | undefined;
    value: number | undefined;
  };
  latency: {
    min: number | undefined;
    max: number | undefined;
  };
  autoGainControl: boolean | undefined;
  echoCancellation: boolean | undefined;
  noiceSuppression: boolean | undefined;
}

export default class Microphone {
  devices: MediaDeviceInfo[];
  selectedDevice?: MediaStream;
  selectedDeviceId?: string; // selected microphone
  stream: MediaStream | undefined;
  track: MediaStreamTrack | undefined;
  enabled: boolean;
  permissionState: string;
  audioContraints: MediaStreamConstraints;
  visualizerId?: string; // unique id for visualizer, if displaying visualizer is wanted
  visualizer: Visualizer;
  onSoundStart?: () => void;
  onSoundEnd?: () => void;
  streamCaptureSettings?: AudioDetectionSettings;
  onInputChange?: (streamId: string, deviceId: string) => void;
  silenceAnalyzerNode?: AudioWorkletNode;
  selectedDeviceOption?: { value: string; label: string };
  constructor(
    streamCaptureSettings?: AudioDetectionSettings,
    onSoundStart?: () => void,
    onSoundEnd?: () => void,
    onInputChange?: (streamId: string, deviceId: string) => void
  ) {
    this.devices = [];
    this.enabled = false;
    this.selectedDevice = undefined;
    this.selectedDeviceId = undefined;
    this.stream = undefined;
    this.track = undefined;
    this.streamCaptureSettings = streamCaptureSettings;
    this.permissionState = "checking";
    this.audioContraints = {
      // sampleSize: 24, // Browser support: https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackSettings/sampleSize
      // channelCount: 2,
      // sampleRate: 96000, //Browser support: https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackSettings/sampleRate
    };
    this.onSoundStart = onSoundStart;
    this.onSoundEnd = onSoundEnd;
    this.visualizer = new Visualizer();
    this.onInputChange = onInputChange;
    this.silenceAnalyzerNode = undefined;
    this.loadMicrophonePermissionState();

    navigator.mediaDevices.ondevicechange = (e) => console.log(e);

    navigator.mediaDevices.addEventListener("devicechange", (event) => {
      this.reloadInputList();
    });

    // Setting bit depth, etc..
    // TODO: Once you know what the browser's capabilities are, your script
    // can use applyConstraints() to ask for the track to be configured to
    // match ideal or acceptable settings. See Capabilities, constraints,
    // and settings for details on how to work with constrainable properties.
    // here: https://developer.mozilla.org/en-US/docs/Web/API/Media_Streams_API/Constraints

    makeAutoObservable(this);

    autorun(() => {
      if (this.stream) {
        this.requestUserPermissionForMicrophone();
      }
    });

    reaction(
      () => this.streamCaptureSettings?.minSoundLevel,
      (minSoundLevel) => {
        if (this.silenceAnalyzerNode) {
          this.silenceAnalyzerNode.port.postMessage({
            type: "MIN_SOUND_LEVEL_UPDATE",
            value: minSoundLevel,
          });
        }
      }
    );

    reaction(
      () => this.streamCaptureSettings?.silenceDelay,
      (silenceDelay) => {
        if (this.silenceAnalyzerNode) {
          this.silenceAnalyzerNode.port.postMessage({
            type: "SILENCE_DELAY_UPDATE",
            value: silenceDelay,
          });
        }
      }
    );
  }

  get currentPermissionState(): string {
    return this.permissionState;
  }

  get isEnabled(): boolean {
    return this.enabled;
  }
  get isReady() {
    const isMicrophoneReady = this.stream !== undefined;
    isMicrophoneReady && audioLog.logMicrophone("Ready");
    return isMicrophoneReady;
  }

  get currentTrack() {
    return this.track;
  }

  get currentMicrophone() {
    const selectedDeviceId = this.selectedDeviceId;
    const selectedInput = this.microphoneOptions.filter(
      (option) => option.value === selectedDeviceId
    );
    if (selectedInput.length > 0) {
      return selectedInput[0];
    }
    return undefined;
  }

  get streamingStream(): MediaStream | undefined {
    if (this.track && this.track.enabled === true) {
      return this.stream;
    }
    return undefined;
  }

  get microphoneOptions(): MicrophoneOption[] {
    if (this.devices.length === 0) {
      return [
        {
          value: "none",
          label: translate("noMic.label"),
          device: "none",
        },
      ];
    }

    return this.devices.map((d) => ({
      device: d,
      value: d.deviceId,
      label: d.label,
    }));
  }

  get systemInputs() {
    return this.devices;
  }

  get microphoneDetails(): MicrophoneInfo {
    const track = this.stream?.getAudioTracks()[0];

    if (!track) {
      return {
        channelCount: {
          min: undefined,
          max: undefined,
          value: undefined,
        },
        sampleRate: {
          min: undefined,
          max: undefined,
          value: undefined,
        },
        sampleSize: {
          min: undefined,
          max: undefined,
          value: undefined,
        },
        latency: {
          min: undefined,
          max: undefined,
        },
        autoGainControl: false,
        echoCancellation: false,
        noiceSuppression: false,
      };
    }
    const settings: any = track.getSettings();
    // A MediaTrackCapabilities object which specifies the value or range
    // of values which are supported for each of the user agent's supported
    // constrainable properties.
    const capabilities = track.getCapabilities();

    return {
      channelCount: {
        min: capabilities.channelCount?.min,
        max: capabilities.channelCount?.max,
        value: settings.channelCount,
      },
      sampleRate: {
        min: capabilities.sampleRate?.min,
        max: capabilities.sampleRate?.max,
        value: settings.sampleRate,
      },
      sampleSize: {
        min: capabilities.sampleSize?.min,
        max: capabilities.sampleSize?.max,
        value: settings.sampleSize,
      },
      latency: {
        min: capabilities.latency?.min,
        max: capabilities.latency?.max,
      },
      autoGainControl: settings.autoGainControl,
      echoCancellation: settings.echoCancellation,
      noiceSuppression: settings.noiseSuppression,
    };
  }

  setVisualizerId(id: string) {
    this.visualizerId = id;
  }
  setMicrophonePermissionState = (state: string) => {
    this.permissionState = state;
  };

  loadMicrophonePermissionState = async () => {
    const microphonePermissions = await navigator.permissions.query({
      //@ts-ignore
      name: "microphone",
    });

    this.setMicrophonePermissionState(microphonePermissions.state);
  };

  // Loads all available input devices in the user's computer
  // and sets the default stream to the user's computer's default
  // selected input device (audio input/microphone)
  init = async (): Promise<boolean> => {
    const defaultLoaded = await this.loadDefault();
    const systemDevicesLodaded = await this.loadSystemDevices();
    if (defaultLoaded && systemDevicesLodaded) {
      const started = await this.startStream();
      return started; // default microphone and system devices loaded successfully
    }
    return false;
  };

  // Load default device (microphone) and set default
  // Promise will return true once the default device has been selected
  loadDefault = async (): Promise<boolean> => {
    const selectedDeviceId = this.selectedDeviceId;
    let deviceId: string | undefined = undefined;
    let audioConstraints: MediaStreamConstraints = { audio: true };
    if (selectedDeviceId) {
      audioConstraints = {
        audio: {
          deviceId: { exact: selectedDeviceId },
          sampleSize: 24,
        },
      };
    }

    this.audioContraints = audioConstraints;

    if (typeof window !== "undefined") {
      if (window.navigator.mediaDevices) {
        return window.navigator.mediaDevices
          .getUserMedia(audioConstraints)
          .then((device: any) => {
            runInAction(() => {
              this.setSelectedDevice(device, deviceId);
            });
            return true;
          })
          .catch((err: any) => {
            return false;
          });
      }
    }
    return new Promise(() => false);
  };

  reloadInputList = async () => {
    await this.loadSystemDevices();
    this.loadDefault();
  };

  // Load all microphone options in the system
  loadSystemDevices = async (): Promise<boolean> => {
    if (typeof window !== "undefined") {
      return window.navigator.mediaDevices
        .enumerateDevices()
        .then((devices: any) => {
          runInAction(() => {
            this.devices = devices.filter((d: any) => d.kind === "audioinput");
            this.showAudioMeter(AudioMeterType.bar);
          });
          audioLog.logMicrophone(
            "Input device list loaded and set successfully"
          );
          return true;
        })
        .catch((err: AsyncGenerator) => {
          return false;
        });
    }
    return new Promise((resolve) => false);
  };

  createSilenceAnalyzerProcessor = (workerFunction: any) => {
    let fnString =
      "(" + workerFunction.toString().replace('"use strict";', "") + ")();";
    let workerBlob = new Blob([fnString], {
      type: "text/javascript",
    });
    return window.URL.createObjectURL(workerBlob);
  };

  addSilenceAnalyser() {
    const onSoundEnd = this.onSoundEnd;
    const onSoundStart = this.onSoundStart;
    const streamingStream = this.streamingStream;
    if (streamingStream && onSoundEnd && onSoundStart) {
      const audioContext = new AudioContext();
      audioContext.audioWorklet
        .addModule(`${window.location.origin}/worklet/silence-analyzer.js`)
        .then(() => {
          const input = audioContext.createMediaStreamSource(streamingStream);
          this.silenceAnalyzerNode = new AudioWorkletNode(
            audioContext,
            "silence-analyzer-processor",
            {
              processorOptions: {
                silenceDelay: DEFAULT_STREAM_DETECTION_SETTINGS.silenceDelay,
                minSoundLevel: DEFAULT_STREAM_DETECTION_SETTINGS.minSoundLevel,
              },
            }
          );

          this.silenceAnalyzerNode.port.onmessage = (ev) => {
            switch (ev.data.type) {
              case "SOUND_START":
                onSoundStart();
                break;
              case "SOUND_END":
                onSoundEnd();
                break;
            }
          };

          input
            .connect(this.silenceAnalyzerNode)
            .connect(audioContext.destination);
        })
        .catch((reason) => {
          let message = `Failed to add silence analyzer module: ${JSON.stringify(
            reason
          )}`;
        });
    }
  }
  setMediaStreamTrack = async (
    stream: MediaStream | undefined
  ): Promise<boolean> => {
    if (stream) {
      const audioTracks = await stream.getAudioTracks();

      this.track = audioTracks[0];
      this.stream = stream;
      audioLog.logMicrophone("Set MediaStream", stream);
      this.addSilenceAnalyser();
      this.enableStream();

      return true;
    } else {
      console.warn("Attempted to set media stream but stream was undefined");
    }
    return false;
  };

  setSelectedDeviceOption = (value: string, label: string) => {
    this.selectedDeviceOption = {
      value,
      label,
    };
  };

  onMicrophoneChange = async (
    selectedInputOption: MicrophoneOption | null | undefined
  ): Promise<boolean> => {
    if (!selectedInputOption) {
      return false;
    }

    this.setSelectedDeviceOption(
      selectedInputOption.value,
      selectedInputOption.label
    );
    this.stopStreaming();
    let audioConstraints: MediaStreamConstraints = { audio: true };
    if (selectedInputOption.value) {
      audioConstraints = {
        audio: {
          deviceId: { exact: selectedInputOption.value },
          sampleSize: 24,
        },
      };
    }

    this.audioContraints = audioConstraints;
    if (typeof window !== "undefined") {
      const stream: MediaStream =
        await window.navigator.mediaDevices.getUserMedia(audioConstraints);

      if (stream) {
        this.setSelectedDevice(stream, selectedInputOption.value);
        await this.startStream();
        this.showAudioMeter(AudioMeterType.bar);
        this.onInputChange &&
          this.onInputChange(selectedInputOption.value, stream.id);
      }
    }
    return false;
  };

  setSelectedDevice = (device: MediaStream, deviceId?: string): void => {
    if (!deviceId) {
      console.log("No deviceId, ", device);
    } else {
      this.selectedDevice = device;
      this.selectedDeviceId = deviceId;
      audioLog.logMicrophone("Set selected device", device, deviceId);
    }
  };

  startStream = async (): Promise<boolean> => {
    console.log("start streaming");
    if (this.selectedDevice) {
      return this.setMediaStreamTrack(this.selectedDevice);
    } else {
      return this.loadDefault();
    }
  };

  stopStreaming = () => {
    console.log("stop streaming");
    if (this.stream) {
      console.log({ stream: this.stream });
      this.stream.getAudioTracks().forEach((track) => {
        track.stop();
      });
    }
    // if (this.track) {
    //   this.track.stop();
    //   audioLog.logMicrophone(
    //     "Stopped streaming microphone - To reuse it again, it needs to be reloaded",
    //   );
    // } else {
    //   console.warn("Attempted to stop track but track was undefined");
    // }
  };

  setEnabled = (value: boolean) => {
    audioLog.logMicrophone("stream enabled", value);
    this.enabled = value;
  };

  enableStream = () => {
    // The enabled property on the MediaStreamTrack interface is a Boolean value which is true
    // if the track is allowed to render the source stream or false if it is not. This can be
    //  used to intentionally mute a track. When enabled, a track's data is output from the
    // source to the destination; otherwise, empty frames are output.
    if (this.track) {
      this.track.enabled = true;
      this.setEnabled(true);
      const settings = this.track.getSettings();
      console.log({ settings });
    } else {
      console.warn("Attempted to pause stream but no track was found");
    }
  };

  disableStream = () => {
    if (this.track) {
      this.track.enabled = false;
      this.setEnabled(false);
    } else {
      console.warn("Attempted to resume stream but no track was found");
    }
  };

  resetMicData = () => {
    this.stream = undefined;
    this.track = undefined;
    audioLog.logMicrophone("Stream reset");
  };

  printStreamInfo = () => {
    if (this.stream) {
      const audioTracks = this.stream.getAudioTracks();
      this.track = audioTracks[0];
      console.log("Num Audio Tracks", audioTracks.length);
      console.log("Capabilities", audioTracks[0].getCapabilities());
      console.log("Contraints", audioTracks[0].getConstraints());
      console.log(
        "Supported Constraints",
        navigator.mediaDevices.getSupportedConstraints()
      );

      console.log("Settings", audioTracks[0].getSettings());
      console.log("Audio tracks", audioTracks);
      const mimeTypes = [
        "audio/wav",
        "audio/mpeg",
        "audio/ogg",
        "audio/opus",
        "audio/webm",
        "audio/webm;codecs=opus",
        "audio/webm;codecs=pcm",
      ];
      mimeTypes.forEach((mimeType) =>
        console.log("format", mimeType, MediaRecorder.isTypeSupported(mimeType))
      );
    } else {
      console.log("Stream is undefined");
    }
  };

  showAudioMeter = async (meterType?: AudioMeterType, canvasId?: string) => {
    if (this.streamingStream) {
      const audioContext = new AudioContext();
      // NOTE: this may output a warning on the console:
      // "Audion could not identify the object calling "connect""
      // The warning is coming from a Chrome extension which is officially
      // called Web Audio Inspector. It's codename is Audion. The source code
      // is available on GitHub.
      // The warning message gets generated here: https://github.com/google/audion/blob/master/js/entry-points/tracing.js#L747
      // I think the problem is that Audion still patches the prototype of the
      // BaseAudioContext but a recent change in the spec moved functions like
      // createMediaElementSource() to the AudioContext prototype.
      // That being said, it's just a warning and it should not stop your website
      // from working correctly.
      // (extracted from stack overflow: https://stackoverflow.com/questions/54640631/how-to-fix-audion-could-not-identify-the-object-calling-connect-error-when-t)

      const input = audioContext.createMediaStreamSource(this.streamingStream);
      const analyzerNode = new AnalyserNode(audioContext, {
        fftSize: 256,
      });

      input.connect(analyzerNode);
      const canvas = document.getElementById(
        canvasId || this.visualizerId || "visualizer"
      ) as HTMLCanvasElement;
      if (canvas) {
        this.visualizer.setCanvas(canvas);
        this.visualizer.visualizeAudioMeter(analyzerNode);
      }
    } else {
    }
  };

  disconnect() {
    this.stopStreaming();
  }

  getLabelForDeviceId(deviceId?: string): string | undefined {
    if (!deviceId) {
      return undefined;
    }
    const result = this.microphoneOptions.filter(
      (option) => option.value === deviceId
    );
    if (result.length > 0) {
      return result[0].label;
    }
    return undefined;
  }

  /**
   * granted — the user has previously given you access to the microphone;
   * prompt — the user has not given you access and will be prompted when
   * you call getUserMedia;
   * denied — the system or the user has explicitly blocked access to the
   * microphone and you won't be able to get access to it.
   */
  requestUserPermissionForMicrophone = () => {
    return (
      navigator.permissions
        //@ts-ignore
        .query({ name: "microphone" })
        .then((result) => {
          if (result.state === "granted") {
          } else if (result.state === "prompt") {
          } else if (result.state === "denied") {
          }
          result.onchange = this.handleInputPermissionChanges;
        })
    );
  };

  handleInputPermissionChanges = (e: any) => {
    console.log("Input permission changed", e);
  };
}
