import { css } from "glamor";
import React from "react";
import AppStore from "./store/AppStore";
import {
  ButtonStylesForLightBackgrounds,
  Color,
  defaultStyles,
  DottedFontFace,
  FontSizes,
  queries,
  setAlpha,
  spacings,
  Text,
  toRem,
} from "./styles/default";
import {
  a,
  body,
  button,
  h1,
  h2,
  html,
  input,
  inputSearch,
  radio,
  textarea,
} from "./styles/global";
import "./styles/fonts.css";
import AppLogo from "./images/logo512.png";
import { user } from "./store/User";
import { logEvent } from "firebase/analytics";
import { observer } from "mobx-react";
import { windowSizes } from "./store/WindowSizesStore";
import WritingSectionComponent from "./components/WritingSectionComponent";
import { WritingSection } from "./store/WritingSection";
import Templates from "./components/Templates";
import { ViewOptionsType } from "./store/View";
import MainNavigation from "./components/MainNavigation";
import { toJS } from "mobx";
import { Game } from "./components/sightWords/Game";
import GameStore from "./store/sightWords/Game";
// import WhiteArrow from "./images/arrow-white.png";

css.global("html", html);
// css.global("@font-face", fontFace);
css.global("@font-face", DottedFontFace);
css.global("button", button);
css.global("a", a);
css.global("h1", h1);
css.global("h2", h2);
css.global("input", input);
css.global("input[type='search']", inputSearch);
css.global("input[type='radio']", radio);
css.global("textarea", textarea);
css.global("p", {
  color: setAlpha(Color.onPrimary, 0.8),
  fontSize: toRem(14),
  lineHeight: "1rem",
});

// Scrollbar
css.global("::-webkit-scrollbar", {
  width: "10px",
  overflow: "overlay",
  display: "none",
});
// track
css.global("::-webkit-scrollbar-track", {
  background: "transparent",
});
// handle
css.global("::-webkit-scrollbar-thumb", {
  background: "transparent",
  borderRadius: "5px",
});
// handle on hover
css.global("::-webkit-scrollbar-thumb:hover", {
  background: "black",
});

css.global("::-webkit-scrollbar-corner", {
  background: "transparent",
});

const App: React.FC<{ store: AppStore }> = ({ store }) => {
  if (store.view.id === ViewOptionsType.game && store.game) {
    return <Game game={store.game} />;
  }
  const showPreview = store.showPreview;

  const backgroundColor = showPreview ? "#FFFFFF" : Color.background;
  css.global("body", body(backgroundColor));
  const viewId = store.view.currentViewId;
  const isTemplates = viewId === ViewOptionsType.templates;
  const isCreateYourOwn = viewId === ViewOptionsType.createYourOwn;

  return (
    <div
      {...css({
        backgroundColor,
        boxSizing: "border-box",
        position: "fixed",
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
        height: windowSizes.height,
        padding: spacings.space4,
        width: "100%",
        [queries.print]: {
          position: "inherit",
          backgroundColor: "#FFFFFF",
          background: "#FFFFFF",
          height: "100%",
        },
      })}
    >
      {user.displayName}
      {showPreview && <HeaderPreview store={store} />}
      {!showPreview && <Header store={store} />}
      {!showPreview && <MainNavigation store={store} />}
      {isTemplates && <Templates store={store} />}
      {isCreateYourOwn && <CreateYourOwn store={store} />}
    </div>
  );
};

export default observer(App);

export const CreateYourOwn: React.FC<{
  store: AppStore;
  showAddAnotherButton?: boolean;
}> = observer(({ store, showAddAnotherButton = true }) => {
  const sections = store.sections;
  const showPreview = store.showPreview;
  return (
    <div
      {...css({
        display: "flex",
        flexDirection: "column",
      })}
    >
      {!showPreview && <InstructionsText />}
      {sections.map((section: WritingSection) => {
        return (
          <WritingSectionComponent
            key={section.id}
            showPreview={showPreview}
            onClosePreview={() => store.setShowPreview(false)}
            onRemove={() => {
              store.deleteWritingSection(section.id);
            }}
            store={store}
            writingSection={section}
          />
        );
      })}
      {!showPreview && showAddAnotherButton && (
        <AddAnotherButton store={store} />
      )}
    </div>
  );
});

const HeaderPreview: React.FC<{ store: AppStore }> = observer(({ store }) => {
  return (
    <div
      {...css({
        padding: spacings.space2,
        display: "flex",
        [queries.print]: {
          visibility: "hidden",
          height: 0,
        },
      })}
    >
      <button
        {...css(defaultStyles.button.default, {
          marginRight: spacings.space2,
          padding: spacings.space2,
        })}
        onClick={() => {
          window.print();
          logEvent(user.analytics, "btn_click_print");
          logEvent(user.analytics, "prt_settings", toJS(store.writingSections));
        }}
      >
        <span {...css(Text.bodyBold)}>Print</span>
        <span
          className="material-icons"
          {...css({ fontSize: FontSizes.fontSize9 })}
        >
          print
        </span>
      </button>
      <button
        {...css(ButtonStylesForLightBackgrounds.default, {
          marginRight: spacings.space2,
          padding: 0,
          paddingLeft: spacings.space2,
          paddingRight: spacings.space2,
        })}
        onClick={() => {
          store.setShowPreview(false);
        }}
      >
        <span
          className="material-icons"
          {...css({ fontSize: FontSizes.fontSize9 })}
        >
          close
        </span>
      </button>
    </div>
  );
});

const Header: React.FC<{ store: AppStore }> = observer(({ store }) => {
  return (
    <div
      {...css({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        width: "100%",
        [queries.print]: {
          visibility: "hidden",
          height: 0,
        },
      })}
    >
      <Logo />
      <PreviewButton store={store} />
    </div>
  );
});

const AddAnotherButton: React.FC<{ store: AppStore }> = observer(
  ({ store }) => {
    return (
      <div>
        <button
          {...css(defaultStyles.button.default, {
            [queries.print]: { height: 0 },
          })}
          onClick={() => {
            store.createNewSection();
            logEvent(user.analytics, "btn_click_addAnother");
          }}
        >
          <span
            className="material-icons"
            {...css({ fontSize: FontSizes.fontSize6 })}
          >
            add
          </span>
          Add another
        </button>
      </div>
    );
  }
);

const Logo: React.FC<{}> = ({}) => {
  return (
    <div {...css({ display: "flex", alignItems: "center" })}>
      <img src={AppLogo} alt="logo" width="32px" />
      <h2 {...css({ marginLeft: spacings.space4, color: Color.secondary })}>
        dottedtext.com
      </h2>
    </div>
  );
};

const PreviewButton: React.FC<{ store: AppStore }> = observer(({ store }) => {
  return (
    <button
      {...css(defaultStyles.button.default)}
      onClick={() => {
        store.setShowPreview(true);
        logEvent(user.analytics, "btn_click_showPreview");
      }}
    >
      <span {...css(Text.bodyBold)}>Preview</span>
      <span
        className="material-icons"
        {...css({ fontSize: FontSizes.fontSize9 })}
      >
        remove_red_eye
      </span>
    </button>
  );
});

const InstructionsText: React.FC<{}> = ({}) => {
  return (
    <div
      {...css({
        [queries.print]: {
          visibility: "hidden",
          height: 0,
        },
      })}
    >
      <p>
        Print practice tracing worksheets for your kids! You can add any text
        using the text input below
      </p>
    </div>
  );
};
