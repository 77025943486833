import { makeAutoObservable } from "mobx";
import { FontFamilies } from "../styles/default";

export const fontLabels = {
  [FontFamilies.dotted]: "Non Cursive (Dotted)",
  [FontFamilies.cursive]: "Cursive (Traced)",
};

export const fontFamilyOptions = [
  { font: FontFamilies.dotted, label: fontLabels[FontFamilies.dotted] },
  { font: FontFamilies.cursive, label: fontLabels[FontFamilies.cursive] },
];

export interface WritingSectionType {
  id: string;
  fontFamily: string;
  fontSize: number;
  label: string;
  repeat: number;
  includeNonDotted: boolean;
  color: string;
  text: string;
}

export class WritingSection {
  id: string;
  fontFamily: string;
  fontSize: number;
  label: string;
  repeat: number;
  includeNonDotted: boolean;
  color: string;
  text: string;
  constructor(props: WritingSectionType) {
    this.id = props.id;
    this.label = props.label;
    this.fontFamily = props.fontFamily;
    this.fontSize = props.fontSize;
    this.repeat = props.repeat;
    this.color = props.color;
    this.includeNonDotted = props.includeNonDotted;
    this.text = props.text;
    makeAutoObservable(this);
  }

  setFontFamily(value: string) {
    this.fontFamily = value;
  }

  setFontSize(value: number) {
    this.fontSize = value;
  }

  setRepeat(value: number) {
    this.repeat = value;
  }

  setText(value: string) {
    this.text = value;
  }

  setIncludeNonDotted(value: boolean) {
    this.includeNonDotted = value;
  }

  setLabel(label: string) {
    this.label = label;
  }
}
