export const sightWords = [
  ["I", "see", "the", "yellow", "look", "green", "blue", "red"],
  [
    "this",
    "is",
    "like",
    "and",
    "for",
    "here",
    "go",
    "purple",
    "pink",
    "orange",
  ],
  ["you", "what", "to", "do", "me", "we", "he", "she", "have", "come", "with"],
  [
    "good",
    "they",
    "brown",
    "white",
    "black",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
  ],
  [
    "how",
    "now",
    "said",
    "put",
    "all",
    "who",
    "your",
    "seven",
    "eight",
    "nine",
    "ten",
  ],
  [
    "how",
    "now",
    "said",
    "put",
    "all",
    "who",
    "your",
    "seven",
    "eight",
    "nine",
    "ten",
  ],
];
