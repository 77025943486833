import React from "react";
import { observer } from "mobx-react";
import { css } from "glamor";
import { flip } from "../../styles/cardFlip";
import { FontFamilies, FontSizes } from "../../styles/default";

const WordCard: React.FC<{
  word: string;
  guess?: string;
  swapCard?: "front" | "back";
}> = ({ word, guess, swapCard }) => {
  return (
    <div {...css(flip.cards)}>
      <div {...css(flip.card)}>
        <div {...css(flip.cardBody)}>
          <div {...css(flip.cardFront)}>
            <Word word={word} guess={guess} />
          </div>

          <div {...css(flip.cardBack)}>
            <Word word={word} guess={guess} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(WordCard);
export const Word: React.FC<{ word: string; guess?: string }> = ({
  word,
  guess,
}) => {
  return (
    <div
      {...css({
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      })}
    >
      <span
        {...css({
          display: "inline-block",
          fontFamily: FontFamilies.game,
          fontSize: FontSizes.fontSize13,
        })}
      >
        {word}
      </span>
      {/* <span>{guess}</span> */}
    </div>
  );
};
