import React from "react";
import { observer } from "mobx-react";
import AppStore from "../store/AppStore";
import Tabs from "./common/Tabs";
import { logEvent } from "@firebase/analytics";
import { user } from "../store/User";
import { Color, FontSizes, queries, spacings } from "../styles/default";

const MainNavigation: React.FC<{
  store: AppStore;
}> = ({ store }) => {
  const tabItems = [
    {
      label: "Traceable Worksheet Creator",
      id: "createYourOwn",
      action: () => {
        logEvent(user.analytics, "btn_open_createYourOwn");
        store.openCreateYourOwnView();
      },
    },
  ];
  const selectedTab = store.view.currentViewId;
  return (
    <div>
      <Tabs
        items={tabItems}
        selectedId={selectedTab}
        style={{
          container: {
            borderBottomWidth: "2px",
            [queries.phone]: { flexDirection: "column" },
          },
          tabItem: {
            container: {
              fontSize: FontSizes.fontSize2,
              fontWeight: 700,
              padding: spacings.space2,
              [queries.phone]: {
                borderBottom: 0,
                fontWeight: 500,
                textAlign: "left",
              },
            },
            selected: {
              fontSize: FontSizes.fontSize2,
              fontWeight: 700,
              borderBottom: `2px solid ${Color.secondary}`,
              [queries.phone]: {
                borderBottom: 0,
                fontWeight: 700,
                color: Color.secondary,
                textAlign: "left",
              },
            },
          },
        }}
      />
    </div>
  );
};

export default observer(MainNavigation);
