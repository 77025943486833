const time = "0.707s";
export const flip = {
  cards: {
    display: "grid",
    gridGap: "1.5rem",
    gridAutoRows: "1fr",
    padding: "3rem",
    gridTemplateColumns: "repeat(auto-fit, minmax(20rem, 1fr))",
  },
  card: {
    perspective: "40rem",
    transition: "z-index, transform calc(var(--time) / 4)",
    transitionDelay: `${time}, 0s`,
    zIndex: "0",
    "&:hover": {
      transitionDelay: "0s",
      zIndex: "1",
    },
  },
  flip: {
    transitionDelay: "0s",
    zIndex: "1",
  },
  cardBody: {
    borderRadius: "0.25rem",
    flex: "1",
    display: "flex",
    transformStyle: "preserve-3d",
    transition: `${time} transform`,

    // ":hover": {
    //   transform: "rotateX(-180deg)",
    // },
  },

  cardFront: {
    backfaceVisibility: "hidden",
    minWidth: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    boxShadow: "0 2px 5px black",
    borderRadius: "0.25rem",
    padding: "1.5rem",
  },
  cardBack: {
    backgroundColor: "green",
    backfaceVisibility: "hidden",
    minWidth: "100%",
    display: "flex",
    alignItems: "center",
    boxShadow: "0 2px 5px black",
    borderRadius: "0.25rem",
    padding: "1.5rem",
    transform: "rotateX(-180deg) translate(-100%, 0)",
  },
};
