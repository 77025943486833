import { makeAutoObservable } from "mobx";
import { v4 } from "uuid";
import { Game } from "../components/sightWords/Game";
import { Color, FontFamilies } from "../styles/default";
import GameStore from "./sightWords/Game";
import View, { ViewOptionsType } from "./View";
import { WritingSection, WritingSectionType } from "./WritingSection";

export default class AppStore {
  writingSections: { [sectionId: string]: WritingSection };
  showPreview: boolean;
  view: View;
  game?: GameStore;
  constructor() {
    this.writingSections = {};
    this.showPreview = false;
    this.view = new View();

    // start with a section
    this.createNewSection({
      id: v4(),
      label: "Pangram (Cursive)",
      text: "A quick brown fox jumps over the lazy dog",
      fontFamily: FontFamilies.cursive,
      fontSize: 100,
      repeat: 1,
      includeNonDotted: false,
      color: Color.onBackground,
    });
    makeAutoObservable(this);
  }

  get sectionCount() {
    return Object.keys(this.writingSections).length;
  }

  get sections() {
    const s = this.writingSections;
    return Object.keys(s).map((sectionId) => this.writingSections[sectionId]);
  }

  initGame = () => {
    this.game = new GameStore();
  };

  openHome = async () => {
    this.view.setView(
      ViewOptionsType.home,
      "/",
      "dottedText.com: Generate Traceable Worksheets For Kids"
    );
    this.openCreateYourOwnView();
  };

  openGame = () => {
    this.initGame();
    this.view.setView(ViewOptionsType.game, "/game", "Sight Words Game");
  };

  openTemplatesView = () => {
    this.view.setView(
      ViewOptionsType.templates,
      "/templates",
      "dottedText.com: Templates. Generate Traceable Worksheets For Kids"
    );
  };

  openCreateYourOwnView = () => {
    this.view.setView(
      ViewOptionsType.createYourOwn,
      "/create",
      "dottedText.com: Generate Traceable Worksheets For Kids"
    );
  };

  openNotFoundView = () => {};

  createNewSection(template?: WritingSectionType) {
    let sample: WritingSectionType = template || {
      id: v4(),
      text: "",
      label: "Custom",
      fontFamily: FontFamilies.cursive,
      fontSize: 100,
      repeat: 1,
      includeNonDotted: false,
      color: Color.onBackground,
    };

    this.addWritingSection(sample);
  }

  setTemplate(template: WritingSectionType) {
    this.addWritingSection(template);
  }

  setShowPreview(value: boolean) {
    this.showPreview = value;
  }

  setWritingSection(section: WritingSectionType) {
    this.writingSections[section.id] = new WritingSection(section);
  }

  resetWritingSections() {
    this.writingSections = {};
  }

  addWritingSection(section: WritingSectionType) {
    this.setWritingSection(section);
  }

  deleteWritingSection(sectionId: string) {
    // cant delete all sections. We need at least one
    if (this.sectionCount > 1) {
      if (this.writingSections[sectionId]) {
        delete this.writingSections[sectionId];
      }
    }
  }
}
