import firebase from "firebase/compat/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { makeAutoObservable } from "mobx";

// import { DocumentTypeOptions } from "./textEditor/Document";
// import Apis from "./Api";
// import { currentLocale } from "./common/i18n/Locale";

class User {
  authenticated: boolean;
  authorized: boolean;
  displayName: string;
  language: string;
  email?: string;
  errorCode?: string;
  errorMessage?: string;
  firebaseUser: any;
  photoURL?: string;
  uid?: string;
  firebase: any;
  allowDiscovery: boolean;
  analytics: any;

  constructor() {
    this.authenticated = false; // NOTE: You can be authenticated but not authorized
    this.authorized = false;
    this.language = "en-US";
    this.allowDiscovery = false;
    this.displayName = "";
    this.initFirebase();

    makeAutoObservable(this);
  }

  initFirebase = () => {
    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
      apiKey: "AIzaSyDL-p37uvDJjnly4RXyLYWTRgSDNofTn_o",
      authDomain: "dottedtext.firebaseapp.com",
      databaseURL: "https://dottedtext-default-rtdb.firebaseio.com",
      projectId: "dottedtext",
      storageBucket: "dottedtext.appspot.com",
      messagingSenderId: "742588648134",
      appId: "1:742588648134:web:9ee12615c008efff9a25c7",
      measurementId: "G-PYJ9TVNRX0",
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    this.analytics = getAnalytics();
    logEvent(this.analytics, "app_initialized");

    // Attach observer
    // firebase.auth().onAuthStateChanged(async (user) => {
    //   if (user) {
    //     // User is signed in, see docs for a list of available properties
    //     // https://firebase.google.com/docs/reference/js/firebase.User
    //     const uid = user.uid;
    //     this.setAuthorized(true);

    //     await this.fetchUserProfile(user?.email);

    //     this.setFirebaseUser(user);
    //     this.setDisplayName(user?.displayName || undefined);
    //     this.setPhotoURL(user?.photoURL || undefined);
    //     this.setUid(uid);
    //     this.setEmail(user?.email || undefined);
    //     this.setAuthenticated(true);
    //   } else {
    //     // User is signed out
    //     this.setAuthorized(false);
    //     this.setAuthenticated(true);
    //   }
    // });
  };

  setFirebase = (firebase: any) => {
    this.firebase = firebase;
  };

  setFirebaseUser = (user: any) => {
    this.firebaseUser = user;
  };

  setAuthorized = (value: boolean) => {
    this.authorized = value;
  };

  setLanguage = (value: string) => {
    this.language = value;
    // currentLocale.setStringsForLocale(value);
  };

  setEmail = (value?: string) => {
    this.email = value;
  };

  setDisplayName = (value?: string) => {
    this.displayName = value || "";
  };

  setUid = (value: string) => {
    this.uid = value;
  };

  setErrorCode = (value?: string) => {
    this.errorCode = value;
  };

  setErrorMessage = (value?: string) => {
    this.errorMessage = value;
  };

  setAuthenticated = (value: boolean) => {
    this.authenticated = value;
  };

  setPhotoURL = (value?: string) => {
    this.photoURL = value;
  };

  fetchUserProfile = async (email: string | null) => {
    // if (!email) {
    //   return;
    // }
    // const profile = await Apis.fetchUserProfile(email);
    // const data = profile.data();
    // this.setAllowDiscovery(data.allowDiscovery);
    // this.setLanguage(data.language);
  };

  setAllowDiscovery = (value?: boolean) => {
    this.allowDiscovery = value || false;
  };

  signupWithEmailAndPassword(email: string, password: string) {
    // this.setAuthenticated(false);
    // // using firebase v8
    // firebase
    //   .auth()
    //   .createUserWithEmailAndPassword(email, password)
    //   .then((userCredential: any) => {
    //     // Signed in
    //     const user = userCredential.user;
    //     this.setEmail(user?.email || undefined);
    //     this.setAuthenticated(true);
    //     this.setAuthorized(true);
    //   })
    //   .catch((error: any) => {
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     this.setAuthenticated(true);
    //     this.setAuthorized(false);
    //     this.setErrorMessage(errorMessage);
    //     this.setErrorCode(errorCode);
    //   });
  }

  // signIn = async (email: string, password: string): Promise<boolean> => {
  // this.setAuthenticated(false);
  // // using firebase v8
  // const auth = await firebase
  //   .auth()
  //   .signInWithEmailAndPassword(email, password)
  //   .then((userCredential: any) => {
  //     // Signed in
  //     const user = userCredential.user;
  //     this.setEmail(user?.email || undefined);
  //     this.setAuthenticated(true);
  //     this.setAuthorized(true);
  //     this.setErrorMessage(undefined);
  //     this.setErrorCode(undefined);
  //     return true;
  //   })
  //   .catch((error) => {
  //     const errorCode = error.code;
  //     const errorMessage = error.message;
  //     this.setAuthenticated(true);
  //     this.setAuthorized(false);
  //     this.setErrorMessage(errorMessage);
  //     this.setErrorCode(errorCode);
  //     return false;
  //   });
  // return auth;
  // };

  updateDisplayName = (displayName: string) => {
    if (this.firebaseUser) {
      // Updates the user attributes:
      this.firebaseUser
        .updateProfile({
          displayName,
        })
        .then(
          () => {
            // Profile updated successfully!
            const displayName = this.firebaseUser.displayName;
            this.setDisplayName(displayName);
          },
          (error: any) => {
            // An error happened.
            console.log({ error });
          }
        );
    }
  };

  updatePhotoUrl = (photoURL: string) => {
    if (this.firebaseUser) {
      // Updates the user attributes:
      this.firebaseUser
        .updateProfile({
          photoURL,
        })
        .then(
          () => {
            // Profile updated successfully!
            const photoURL = this.firebaseUser.photoURL;
            this.setPhotoURL(photoURL);
          },
          (error: any) => {
            // An error happened.
            console.log({ error });
          }
        );
    }
  };

  logout = () => {
    // firebase.auth().signOut();
    this.setAuthorized(false);
    this.setAuthenticated(true);
    this.setEmail(undefined);
    this.setDisplayName(undefined);
    this.setPhotoURL(undefined);
  };
}

export default User;
export const user = new User();
