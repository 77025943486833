import React from "react";
import { observer } from "mobx-react";
import AppStore from "../store/AppStore";

const Templates: React.FC<{
  store: AppStore;
}> = ({ store }) => {
  const showPreview = store.showPreview;
  if (showPreview) {
    return null;
  }

  return <div>templates</div>;
};

export default observer(Templates);
