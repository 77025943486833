import { css, CSSProperties } from "glamor";
import { observer } from "mobx-react";
import { defaultStyles, FontSizes, spacings, Text } from "../../styles/default";
import { Input } from "../Input";

const InputWithArrows: React.FC<{
  onChange: (value: string) => void;
  value: string;
  label: string;
  styles?: CSSProperties;
}> = ({ onChange, value = "", label, styles }) => {
  return (
    <div
      {...css({
        display: "flex",
        flexDirection: "column",
      })}
    >
      <span {...css(Text.bodyBold)}>{label}</span>
      <div
        {...css({
          display: "flex",
        })}
      >
        <span
          {...css({
            display: "flex",
            alignItems: "center",
            fontWeight: 700,
          })}
        >
          <Input
            value={value}
            onChange={(e: any) => {
              onChange(e.target.value);
            }}
            style={{
              input: {
                margin: 0,
                paddingRight: 0,
                border: 0,
                ...styles,
              },
            }}
          />
        </span>
        <div
          {...css({
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            marginLeft: spacings.space4,
          })}
        >
          <button
            {...css(defaultStyles.button.primary, {
              padding: 0,
            })}
            onClick={() => {
              const v = parseInt(value);
              if (v) {
                if (v > 0) {
                  onChange(v + 1 + "");
                }
              }
            }}
          >
            <span
              className="material-icons"
              {...css({ fontSize: FontSizes.fontSize4 })}
            >
              expand_less
            </span>
          </button>
          <button
            {...css(defaultStyles.button.primary, {
              padding: 0,
            })}
            onClick={() => {
              const v = parseInt(value);
              if (v) {
                if (v > 1) {
                  onChange(v - 1 + "");
                }
              }
            }}
          >
            <span
              className="material-icons"
              {...css({ fontSize: FontSizes.fontSize4 })}
            >
              keyboard_arrow_down
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};
export default observer(InputWithArrows);
