import { css } from "glamor";
import { observer } from "mobx-react";
import AppStore from "../store/AppStore";
import { WritingSection } from "../store/WritingSection";

const TextRepetition: React.FC<{
  writingSection: WritingSection;
  store: AppStore;
}> = observer(({ store, writingSection }) => {
  const isPreview = store.showPreview;
  const { includeNonDotted, repeat, fontSize, fontFamily, text, color } =
    writingSection;
  const repetitions = Array.from(Array(repeat).keys());
  const textColor = isPreview ? "#000000" : color;

  return (
    <div
      {...css({
        display: "flex",
        flexDirection: "column",
      })}
    >
      {includeNonDotted && (
        <div
          {...css({
            fontSize,
            fontFamily,
            color: textColor,
          })}
        >
          {text}
        </div>
      )}
      {repetitions.map((no) => {
        return (
          <div
            key={no}
            {...css({
              fontSize,
              fontFamily,
              color: textColor,
            })}
          >
            {text}
          </div>
        );
      })}
    </div>
  );
});

export default TextRepetition;
